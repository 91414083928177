import React, { useEffect, useRef, useState } from 'react'
import { get, set } from 'idb-keyval'
import { getPendingJobs, getShippedJobs, strapi } from '../api'
import Button from './UI/Button'
import Label from './UI/Label'
import Loader from './UI/Loader'
import { printIframe, verifyPermission, writeFile } from '../utils'
import { useIntervalWhen } from 'rooks'
import moment from 'moment'
import { FiAlertTriangle, FiCheck, FiDownload, FiLoader, FiPrinter } from 'react-icons/fi'
import Checkbox from './UI/Checkbox'

const isBrowser = () => typeof window !== 'undefined'
let printJS
if (typeof window !== `undefined`) import('print-js').then(e => { printJS = e.default })

function Job({ job, download, downloaded, print, printed, done, showSite }) {
  const recent = moment(job.metadata.confirmed_at).isAfter(moment().subtract(10, 'minutes'))
  return (
    <div className={`bg-white w-64 m-4 p-4 relative rounded shadow ${recent && 'border-4 border-blue-dark pt-6'}`}>
      {recent && <div className="text-center w-full text-xs absolute left-0 top-0 bg-blue-dark text-white -mt-1">Nouveau</div>}
      <div className="flex justify-between">
        <div className="font-bold text-md">{job.code}</div>
        <div>{moment(job.metadata.confirmed_at).format('DD/MM HH:mm')}</div>
      </div>
      <div className="flex flex-wrap">
        {showSite &&
          <div className="text-white flex justify-center rounded px-2 mr-2 mb-1 text-xs bg-gray-400">{job.site.name}</div>
        }
        <div className={`text-white flex justify-center rounded px-2 mr-2 mb-1 text-xs ${job.metadata.custom.stripe_payment_id ? 'bg-blue-dark' : 'bg-orange'}`}>
          {job.metadata.custom.stripe_payment_id ? 'Payée' : 'À payer'}
        </div>
        {job.metadata.orderlines.length > 1 && [...new Set(job.metadata.orderlines.map(ol => ol.fulfillment_code))].length > 1 &&
          <div className="text-white flex justify-center items-center rounded px-2 mr-2 mb-1 text-xs bg-red"><FiAlertTriangle className="mr-1" /> Multi-sites</div>
        }
      </div>
      <div className="text-sm font-thin mb-1">{job.metadata.user.first_name} {job.metadata.user.last_name}</div>
      {job.metadata.custom.order_notes && job.metadata.custom.order_notes.length > 0 &&
        <div className="text-xs leading-tight bg-gray-100 px-2 py-1 opacity-75 rounded font-thin" dangerouslySetInnerHTML={{ __html: job.metadata.custom.order_notes.replace('\r', '<br/>') }} />
      }
      <div className="flex justify-center items-center -mb-9 mt-1 transform" style={{ transform: 'scale(0.75)' }}>
        <Button Icon={FiDownload} variant="icon" className={`mx-2 ${downloaded && 'bg-green'}`} onClick={download} label="Télécharger" />
        <Button Icon={FiPrinter} variant="icon" className={`mx-2 ${printed && 'bg-green'}`} onClick={print} label="Imprimer" />
        <Button Icon={FiCheck} variant="icon" className={`mx-2 ${job.status === 'shipped' && 'bg-green'}`} onClick={done} label="Notifier" />
      </div>
    </div>
  )
}

function WizitoJob({ job, download, downloaded, print, printed, done, showSite }) {
  const recent = moment(job.metadata.confirmed_at).isAfter(moment().subtract(10, 'minutes'))
  const formats = job.metadata.photos.reduce((acc, curr) => { acc[curr.template] = (acc[curr.template] || 0) + (curr.quantity || 1); return acc }, {})
  const p = () => {
    print({ ...job, ticket: `
      <html>
        <head>
          <link rel="preconnect" href="https://fonts.googleapis.com">
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin>
          <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap" rel="stylesheet">
          <link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet">
          <style>* { font-family: 'Roboto', sans-serif } html { font-size: 1.5em }" }}</style>
        </head>
        <body>
          <div class="p-10">
            <div class="text-xl mb-6"><strong>${job.code}</strong>&nbsp;&nbsp;-&nbsp;&nbsp;${job.site.name}</div>
            ${job.email ? `<div class="flex items-center mb-2"><div class="w-28 text-right font-bold text-sm mr-4">Email</div> ${job.email}</div>` : ''}
            <div class="flex items-center mb-2"><div class="w-28 text-right font-bold text-sm mr-4">Nom</div> ${job.metadata.clientName}</div>
            <div class="flex items-center mb-2"><div class="w-28 text-right font-bold text-sm mr-4">Borne</div> ${job.metadata.booth}</div>
            <div class="flex items-center mb-2"><div class="w-28 text-right font-bold text-sm mr-4">Date</div> ${moment(job.metadata.confirmed_at).format('DD/MM HH:mm')}</div>
            <div class="flex items-start mb-2"><div class="w-28 text-right font-bold text-sm mr-4">Commande</div>
              <div>
                ${Object.keys(formats).map(format => 
                  `<li class="leading-tight">${[formats[format]]}<span> x </span>${format}</li>`
                ).join('')}
              </div>
            </div>
          </div>
        </body>
      </html>
    ` })
  }
  return (
    <div className={`bg-white w-64 m-4 p-4 relative rounded shadow ${recent && 'border-4 border-blue-dark pt-6'}`}>
      {recent && <div className="text-center w-full text-xs absolute left-0 top-0 bg-blue-dark text-white -mt-1">Nouveau</div>}
      <div className="flex justify-between">
        <div className="font-bold text-md">{job.code}</div>
        <div>{moment(job.metadata.confirmed_at).format('DD/MM HH:mm')}</div>
      </div>
      <div className="flex flex-wrap">
        {showSite &&
          <div className="text-white flex justify-center rounded px-2 mr-2 mb-1 text-xs bg-gray-400">{job.site.name}</div>
        }
        <div className={`text-white flex justify-center rounded px-2 mr-2 mb-1 text-xs`} style={{ backgroundColor: '#1ec6d5' }}>
          Borne Wizito
        </div>
      </div>
      <div className="text-sm font-thin mb-1">{job.metadata.clientName} (Borne {job.metadata.booth})</div>
      <div className="bg-gray-100 px-2 py-1 opacity-75 rounded font-thin">
        {Object.keys(formats).map(format => 
          <li key={format} className="text-xs leading-tight">{[formats[format]]}<span> x </span>{format}</li>
        )}
      </div>
      <div className="flex justify-center items-center -mb-9 mt-1 transform" style={{ transform: 'scale(0.75)' }}>
        <Button Icon={FiDownload} variant="icon" className={`mx-2 ${downloaded && 'bg-green'}`} onClick={download} label="Télécharger" />
        <Button Icon={FiPrinter} variant="icon" className={`mx-2 ${printed && 'bg-green'}`} onClick={p} label="Imprimer" />
        <Button Icon={FiCheck} variant="icon" className={`mx-2 ${job.status === 'shipped' && 'bg-green'}`} onClick={done} label="Notifier" />
      </div>
    </div>
  )
}

const downloadSlots = [null, null, null]

export default function Jobs({ directory, user, downloaded, fetchDownloaded, forceReload, autoDownload }) {
  const [jobs, setJobs] = useState(null)
  const [shippedJobs, setShippedJobs] = useState(null)
  const [printHTML, setPrintHTML] = useState(null)
  const [siteFilter, setSiteFilter] = useState([])
  const [searchFilter, setSearchFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('pending')
  const [printedJobs, setPrintedJobs] = useState([])
  const iframe = useRef()

  useEffect(() => {
    if (forceReload) getPendingJobs().then(setJobs)
  }, [forceReload])

  useIntervalWhen(() => {
    getPendingJobs().then(setJobs)
  }, 45 * 1000, true, true)

  useIntervalWhen(() => {
    getShippedJobs().then(setShippedJobs)
  }, 2 * 60 * 1000, true, true)

  useIntervalWhen(() => {
    if (searchFilter.length > 2) return
    console.log('Download slots', downloadSlots)
    const slot = downloadSlots.findIndex(s => s === null)
    if (slot === -1 || !downloaded) return
    const pool = (jobs || []).filter(j =>
      j.status === 'pending'
      && siteFilter.includes(j.site && j.site.externalId)
      && !downloaded.find(d => j.code === d)
      && !downloadSlots.find(d => d === j.code)
    )
    console.log('Download Pool', pool.map(p => p.code))
    downloadJob(pool[0], slot)
  }, 2 * 1000, !!autoDownload, true)

  useEffect(() => {
    get('lis-wizi-jobs').then(jobs => {
      if (!jobs) return
      setPrintedJobs(JSON.parse(jobs))
    })
  }, [])

  useEffect(() => {
    set('lis-wizi-jobs', JSON.stringify(printedJobs))
  }, [printedJobs])

  useEffect(() => {
    if (user)
      setSiteFilter(user.sites.map(s => s.externalId))
  }, [user])

  // Replaced by modifying job ticket body with onload
  // useEffect(() => {
  //   if (iframe.current) {
  //     printIframe(iframe.current, () => setPrint(null))
  //   }
  // }, [iframe, print])

  const downloadJob = async (job, slot) => {
    if (!isBrowser || !job) return
    await verifyPermission(directory)
    downloadSlots[slot] = job.code
    console.log('Downloading', job.code, slot)
    const newDir = await directory.getDirectoryHandle(job.code, { create: true })
    for (const path of job.files) {
      const file = await fetch(path).then(r => r.blob())
      const customName = path.match(/\?filename=(.*\.jpg)/)
      const name = customName ? customName[1] : path.match(/[^\/:\?]*\.(\w+)$/i)[0]
      await writeFile(file, name, newDir)
    }
    if (job.ticket.match(/<body>/))
      await writeFile(job.ticket, 'jobticket.html', newDir)
    await writeFile('', 'end.txt', newDir)
    await fetchDownloaded()
    downloadSlots[slot] = null
  }

  const setPrintStatus = async job => {
    await strapi.update('jobs', job.id, { status: 'shipped' })
    job.status = 'shipped'
    setJobs([...jobs])
  }

  const printJobTicket = job => {
    if (job.provider.name !== "Pixfizz")
      setPrintHTML(job)
    else if (job.ticket.match(/<body>/))
      setPrintHTML(job)
    else
      printJS({ printable: job.ticket, type: 'pdf' })
    setPrintedJobs([...printedJobs, job.id])
  }

  if (!jobs) return <Loader />

  let filtered = [...(jobs || []), ...(shippedJobs || [])]
    .filter(j => searchFilter.length > 2 || siteFilter.includes(j.site && j.site.externalId))
    .filter(j => j.status === statusFilter)
    .filter(j => !searchFilter || searchFilter.length < 2 || [j.code, j.email, j.metadata.user && j.metadata.user.first_name && j.metadata.user.last_name, j.metadata.clientName].join(' ').match(new RegExp(searchFilter, 'i')))
    .sort((a, b) => new Date(b.metadata.confirmed_at) - new Date(a.metadata.confirmed_at))

  return (
    <div className="flex flex-wrap justify-center items-start">
      <div className="flex justify-center w-full">
        <div className="mx-5">
          <Label title="Rechercher">
            <input type="text" onChange={e => setSearchFilter(e.target.value)} />
          </Label>
        </div>
        {user && user.sites.length > 1 &&
          <div className="mx-5">
            <Label title="Lieu">
              <div className="flex">
                {user.sites.map(s =>
                  <div key={s.id}>
                    <Checkbox
                      checked={siteFilter.find(f => f === s.externalId)}
                      onClick={() => siteFilter.find(f => f === s.externalId) ? setSiteFilter(siteFilter.filter(f => f !== s.externalId)) : setSiteFilter([...siteFilter, s.externalId])}>
                      {s.name}
                    </Checkbox>
                  </div>
                )}
              </div>
            </Label>
          </div>
        }
        <div className="mx-5">
          <Label title="Status">
            <div className="flex">
              {[{ id: 'pending', name: 'À traiter' }, { id: 'shipped', name: 'Historique' }].map(s =>
                <div key={s.id}>
                  <Checkbox
                    checked={s.id === statusFilter}
                    onClick={() => setStatusFilter(s.id)}>
                    {s.name}
                  </Checkbox>
                </div>
              )}
            </div>
          </Label>
        </div>
      </div>
      {filtered.map(job =>
        job.provider.name !== "Pixfizz"
          ? <WizitoJob
              key={job.id}
              job={job}
              download={() => downloadJob(job)}
              downloaded={downloaded ? downloaded.find(f => f === job.code) : undefined}
              print={j => printJobTicket(j)}
              printed={printedJobs.find(j => j === job.id)}
              done={() => setPrintStatus(job)}
              showSite={siteFilter.length > 1} />
          : <Job
              key={job.id}
              job={job}
              download={() => downloadJob(job)}
              downloaded={downloaded ? downloaded.find(f => f === job.code) : undefined}
              print={() => printJobTicket(job)}
              printed={printedJobs.find(j => j === job.id)}
              done={() => setPrintStatus(job)}
              showSite={siteFilter.length > 1} />
      )}
      {printHTML &&
        <iframe className="fixed bg-white right-full" style={{ width: 560, height: 700 }} srcDoc={printHTML.ticket.replace('<body>', '<body onload="window.print()">')} />
      }
    </div>
  )
}