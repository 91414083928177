import React, { useState } from 'react'
import { strapi } from '../api'
import Button from './UI/Button'
import Label from './UI/Label'
import Popup from './UI/Popup'
import { Helmet } from 'react-helmet'

export default function Auth({ children }) {
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const [jwt, setJwt] = useState(strapi.getToken())
  if (jwt) return <div>{children}</div>
  const connect = async () => {
    const userData = await strapi.login({ identifier: user, password: password })
    setJwt(userData.jwt)
  }
  return (
    <div>
      <Popup title="Connexion">
        <Helmet>
          <title>Connexion - Wizito</title>
        </Helmet>
        <form className="text-left" onSubmit={e => e.preventDefault()}>
          <Label title="Utilisateur">
            <input type="text" value={user} onChange={e => setUser(e.target.value)} />
          </Label>
          <Label title="Mot de passe">
            <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
          </Label>
          <Button onClick={connect} className="mt-2 mx-auto" submit>Valider</Button>
        </form>
      </Popup>
    </div>
  )
}
