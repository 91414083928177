import React from 'react'
import { FiX } from 'react-icons/fi'
import { useKeyboardEvent, DisableBodyScroll } from '../../utils'
import Title from './Title'

export default function Popup({ children, close, title, className }) {
  useKeyboardEvent('Escape', close)
  return (
    <>
      <DisableBodyScroll />
      <div
        className={`fixed flex justify-center items-center h-screen w-screen top-0 left-0 z-40 cursor-default ${className}`}>
        <div
          onClick={close}
          className="fixed h-screen w-screen top-0 left-0 opacity-50" style={{ backgroundColor: '#f0f2f5' }} />
        <div className="relative bg-white max-w-xl w-full h-auto max-h-full overflow-y-auto p-6 rounded shadow">
          <FiX
            onClick={close}
            className="absolute top-0 right-0 m-1 lg:m-4 w-8 h-8 cursor-pointer stroke-1 z-40" />
          <Title className="text-center -mt-5 lg:-mt-2">{title}</Title>
          <div className="flex flex-col justify-between items-center flex-wrap text-center min-h-full">
            {children}
          </div>
        </div>
      </div>
    </>
  )
}
