import React, { useEffect, useState } from 'react'
import { get, set } from 'idb-keyval'
import Auth from '../components/Auth'
import Jobs from '../components/Jobs'
import { refreshProvider, strapi } from '../api'
import { checkDownloads, verifyPermission, useIsClient } from '../utils'
import Button from '../components/UI/Button'
import { FiFolderPlus, FiLogOut, FiPause, FiPlay, FiRefreshCcw } from 'react-icons/fi'
import Helmet from 'react-helmet'
import { useIntervalWhen } from 'rooks'
import Popup from '../components/UI/Popup'
const isBrowser = () => typeof window !== 'undefined'

const IndexPage = () => {
  const [directory, setDirectory] = useState()
  const [user, setUser] = useState()
  const [downloaded, setDownloaded] = useState([])
  const [fileApiReady, setFileApiReady] = useState(false)
  const [forceReload, setForceReload] = useState()
  const [autoDownload, setAutoDownload] = useState()

  const getDownloaded = async () => {
    checkDownloads(directory).then(jobs => {
      console.log('Downloaded jobs', jobs.length)
      setDownloaded([...new Set([...downloaded, ...jobs])])
      setFileApiReady(true)
    })
      .catch(async e => {
        console.log('ERROR', e)
        setDirectory(null)
      })
  }

  useEffect(() => {
    get('lis-wizi-downloaded').then(jobs => {
      if (!jobs) return
      setDownloaded(JSON.parse(jobs))
    })
  }, [])

  useEffect(() => {
    if (downloaded && downloaded.length > 0)
      set('lis-wizi-downloaded', JSON.stringify(downloaded))
  }, [downloaded])

  useIntervalWhen(() => {
    if (directory) getDownloaded()
  }, 10 * 1000, undefined, true)

  useEffect(() => {
    get('lis-wizi-file').then(handle => {
      if (!handle) return
      setDirectory(handle)
    })
    strapi.fetchUser().then(setUser)
  }, [])

  useEffect(() => {
    if (directory) set('lis-wizi-file', directory)
  }, [directory])

  const changeDirectory = async () => {
    if (!isBrowser) return
    const handle = await window.showDirectoryPicker()
    await verifyPermission(handle)
    setDirectory(handle)
  }

  const refrehProvider = async () => {
    await refreshProvider()
    setForceReload(Date.now())
  }

  return (
    <div>
      <Helmet>
        <title>LIS Photo Production</title>
      </Helmet>
      <div className="fixed top-0 left-0 h-12 lg:h-16 px-1 lg:px-3 flex flex-col lg:flex-row justify-center lg:justify-between items-center bg-white shadow w-full z-20">
        <div className="hidden lg:flex items-center flex-shrink-0">
          <div className="lg:text-2xl font-thin ml-4 mr-4">LIS PHOTO PRODUCTION</div>
        </div>
        <div className="flex w-full justify-center items-center lg:justify-end">
          <div className="text-sm leading-tight mr-2">
            {user &&
              <div className="flex">
                <div className="w-20 font-thin text-right mr-2">Utilisateur</div> {user.username}
              </div>
            }
            {directory &&
              <div className="flex">
                <div className="w-20 font-thin text-right mr-2">Dossier</div> {directory.name}
              </div>
            }
          </div>
          <Button
            Icon={autoDownload ? FiPlay : FiPause}
            variant="icon"
            className={autoDownload ? "ml-2" : "ml-2 opacity-30"}
            onClick={() => setAutoDownload(!autoDownload)}
            label={autoDownload ? 'Téléchargement automatique activé' : 'Téléchargement en pause'} />
          <Button Icon={FiFolderPlus} variant="icon" className="ml-2" onClick={changeDirectory} label="Dossier destination" />
          <Button Icon={FiRefreshCcw} variant="icon" className="ml-2" onClick={refrehProvider} label="Rafraichir commandes" />
          <Button Icon={FiLogOut} variant="icon" className="ml-2" onClick={() => { strapi.logout(); window.location.reload() }} label="Deconnexion" />
        </div>
      </div>
      <div className="mt-20 mb-20">
        <Jobs directory={directory} user={user} downloaded={fileApiReady ? downloaded : undefined} fetchDownloaded={getDownloaded} forceReload={forceReload} autoDownload={autoDownload} />
      </div>
      {!fileApiReady &&
        <Popup title="Téléchargement automatique">
          <div className="flex">
            <Button onClick={() => { getDownloaded(); setAutoDownload(false) }} className="mx-2">Ne pas activer</Button>
            <Button onClick={() => { getDownloaded(); setAutoDownload(true) }} className="mx-2">Activer</Button>
          </div>
        </Popup>
      }
      {!directory &&
        <Popup title="Choisir un dossier de téléchargement">
          <Button onClick={changeDirectory}>Choisir</Button>
        </Popup>
      }
    </div>
  )
}

export default function AuthIndexPage() {
  const { isClient, key } = useIsClient()
  if (!isClient) return null

  return (
    <Auth>
      <IndexPage />
    </Auth>
  )
}
