import React, { useState } from 'react'
import { FiLoader } from 'react-icons/fi'

export default function Button({ onClick, href, children, style, className, variant = 'default', Icon, disabled = false, label, submit }) {
  const [loading, setLoading] = useState(false)
  const iconStyles = {
    default: 'text-lg stroke-2 mr-2 -ml-1',
    secondary: 'text-lg stroke-2 mr-2 -ml-1',
    small: 'stroke-2 mr-2',
    xsmall: 'stroke-2 mr-1',
    icon: 'text-lg stroke-2 -mx-1'
  }
  const styles = {
    default: `text-base font-semibold py-1 lg:py-2 px-3 lg:px-6 bg-orange hover:bg-orange-dark text-white`,
    secondary: `text-base font-semibold py-1 lg:py-2 px-3 lg:px-6 bg-blue-dark bg-opacity-90 hover:opacity-75 text-white`,
    small: `text-sm font-normal py-1 px-2 lg:px-3 bg-white text-blue-dark hover:border-opacity-10 border border-blue-dark border-opacity-5`,
    xsmall: `text-sm font-normal py-2 px-0.5 px-1.5 text-blue-dark border border-gray-100 shadow-sm`,
    icon: `h-6 w-6 py-1 lg:py-2 px-2 lg:px-2 box-content bg-blue-dark bg-opacity-100 hover:opacity-75 text-white`,
  }
  return (
    <a href={href} target={href && href.match('http') ? '_blank' : ''}>
      <button
        type={submit && 'submit'}
        onClick={async () => {
          if (disabled) return
          setLoading(true)
          try {
            await onClick()
          } catch (error) {
            setLoading(false)
          }
          setLoading(false)
        }}
        disabled={disabled || loading}
        style={style}
        className={`relative flex justify-center items-center align-middle focus:outline-none select-none flex-shrink-0 transition-all duration-100 rounded shadow-sm hover:shadow ${styles[variant]} ${className} ${disabled && 'bg-gray-200 hover:bg-gray-200'}`}>
        {Icon && <Icon className={iconStyles[variant]} />}
        {children}
        {loading &&
          <div className={`absolute w-full h-full p-1 bg-gray-100`}>
            <FiLoader className="stroke-1 rotating box-border w-full h-full" />
          </div>
        }
        {label &&
          <div className="absolute w-full h-full opacity-0 hover:opacity-100 transition-opacity duration-150">
            <div
              className="absolute top-full w-24 leading-none p-1 bg-blue-dark text-sm text-white rounded"
              style={{ left: '50%', transform: 'translateX(-50%)' }}>
              {label}
            </div>
          </div>
        }
      </button>
    </a>
  )
}
