import React from 'react'
import { FiCheck, FiLoader } from 'react-icons/fi'

// @apply bg-white;
// @apply border-gray-100;
// @apply rounded;
// @apply shadow-sm;
// @apply text-sm;
// @apply border;
// @apply text-gray-700;
// @apply my-1;
// @apply focus:ring-0;
// @apply focus:border-gray-200;
// @apply focus:shadow;
// @apply outline-none;
// @apply py-1;

export default function Checkbox({ checked, onClick, className, loading, children }) {
  return (
    <div className="flex items-center mr-3 text-xs font-thin cursor-pointer" onClick={!loading && onClick}>
      <div
        className={`flex justify-center my-1 rounded items-center mr-1 flex-shrink-0 w-8 h-8 bg-white shadow-sm border border-gray-100 text-gray-700 hover:border-gray-200 hover:shadow ${className}`}>
        {loading && <FiLoader className="h-4 w-4 stroke-1 rotating box-border" />}
        {!loading && checked && <FiCheck className="stroke-1 w-5 h-5" />}
      </div>
      {children}
    </div>
  )
}
