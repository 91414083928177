import React from 'react'

export default function Title({ children, style, className, id, white }) {
  return (
    <div
      id={id}
      style={style}
      className={`text-xl lg:text-2xl font-thin mt-2 mb-6 relative leading-tight ${className} ${white ? 'text-white' : 'text-gray-800'}`}>
      {children}
    </div>
  )
}
