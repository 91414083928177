import React, { useEffect, useState } from 'react'

export const useIsClient = () => {
  const [isClient, setClient] = useState(false)
  const key = isClient ? 'client' : 'server'

  useEffect(() => {
    setClient(true)
  }, [])

  return { isClient, key }
}


export function useKeyboardEvent(key, callback) {
  useEffect(() => {
    const handler = event => {
      if (event.key === key) callback()
    }
    document.addEventListener('keydown', handler)
    return () => {
      document.removeEventListener('keydown', handler)
    }
  })
}

export function DisableBodyScroll() {
  return <style dangerouslySetInnerHTML={{ __html: 'body { overflow: hidden }' }} />
}

export function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function normalizeStrapi(input) {
  if (!input) return input
  if (typeof input === 'string') return input
  if (Array.isArray(input))
    return input.map(i => normalizeStrapi(i))
  if (Object.keys(input).includes('data') && Object.keys(input).length <= 2)
    return normalizeStrapi(input.data)
  else if (Object.keys(input).includes('attributes') && Object.keys(input).length <= 2)
    return { ...normalizeStrapi(input.attributes), id: input.id }
  else {
    return Object.keys(input).reduce((acc, curr) => {
      if (Array.isArray(input[curr]))
        acc[curr] = input[curr].map(e => normalizeStrapi(e))
      else if (typeof input[curr] === 'object')
        acc[curr] = normalizeStrapi(input[curr])
      else
        acc[curr] = input[curr]
      return acc
    }, {})
  }
}

export function printIframe(iframe, callback) {
  const win = iframe.contentWindow || iframe
  iframe.focus()
  win.print()
  win.onafterprint = callback
}

export async function verifyPermission(handle) {
  const options = { mode: 'readwrite' }
  if ((await handle.queryPermission(options)) === 'granted')
    return true
  if ((await handle.requestPermission(options)) === 'granted')
    return true
  return false
}

export async function writeFile(file, name, folder) {
  const newFileHandle = await folder.getFileHandle(name, { create: true })
  const writable = await newFileHandle.createWritable()
  await writable.write(file)
  await writable.close()
}

export async function checkDownloads(handle) {
  await verifyPermission(handle)
  let completedDownloads = []
  for await (const [key, value] of handle.entries()) {
    if (value.kind === 'directory')
      for await (const name of value.keys()) {
        if (name === 'end.txt') completedDownloads.push(key)
      }
  }
  return completedDownloads
}
