import React from 'react'

export default function Label({ children, title, className }) {
  return (
    <label className={`block text-sm leading-none mt-2 mb-1 ${className}`}>
      <div style={{ marginBottom: -2 }}>{title}</div>
      {children}
    </label>
  )
}
