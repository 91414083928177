import Strapi from 'strapi-sdk-js'
import { normalizeStrapi, timeout } from './utils'
import moment from 'moment'

const backUrl = 'https://lis-prod-back.wizito.com' //'http://localhost:1337'

export const strapi = new Strapi({ url: backUrl, prefix: '/api' })

export function getPendingJobs() {
    return strapi.find('jobs', {
        filters: { status: 'pending' },
        populate: { site: { fields: ['name', 'externalId'] }, provider: { fields: ['name'] } },
        fields: ['id', 'code', 'ticket', 'status', 'files', 'metadata', 'email'],
        pagination: { start: 0, limit: 1000 }
    }).then(normalizeStrapi)
}

export function getShippedJobs() {
    return strapi.find('jobs', {
        filters: { status: 'shipped', createdAt : { $gt: moment().subtract(2, 'day').toISOString() } },
        populate: { site: { fields: ['name', 'externalId'] }, provider: { fields: ['name'] } },
        fields: ['id', 'code', 'ticket', 'status', 'files', 'metadata', 'email'],
        sort: 'createdAt:desc',
        pagination: { start: 0, limit: 500 }
    }).then(normalizeStrapi)
}

export function refreshProvider() {
    return fetch(`${backUrl}/api/providers/1/refresh`, { method: 'PUT' })
}

export function downloadFromFtp(site, file) {
    console.log(site, file)
    return strapi.request('get', `/providers/${site}/download/${encodeURIComponent(file)}`, { responseType: 'blob' })
}